<template>
    <v-container>
        <v-alert type="warning" class="mt-5" border="left" elevation="3" colored-borderx prominent>
            <h1 class="heading font-weight-light">Not Found</h1>
            The page you requested could not be found.
        </v-alert>
    </v-container>
</template>

<script>
    import {useTitle} from '@vueuse/core';

    export default {
        mounted(){
            useTitle('Page Not Found - myGMRS.com');
        }
    }
</script>
